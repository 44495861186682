<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 管理员管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch">
				<a-row :gutter="24">
					<a-col :span="4">
						<a-form-item :label="`账号类型：`">
							<a-select placeholder="请选择账号类型" v-model="searchData.type_id">
								<a-select-option :value="0"> 全部 </a-select-option>
								<a-select-option v-for="(item, index) in typeList" :key="index" :value="item.value">
									{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`登录帐号：`">
							<a-input placeholder="请输入登录帐号" v-model="searchData.admin_name" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`联系人：`">
							<a-input placeholder="请输入联系人" v-model="searchData.real_name" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`帐号状态：`">
							<a-select placeholder="请选择帐号状态" v-model="searchData.is_lock">
								<a-select-option :value="0"> 全部 </a-select-option>
								<a-select-option :value="2"> 锁定 </a-select-option>
								<a-select-option :value="1"> 正常 </a-select-option>
							</a-select>
						</a-form-item>
					</a-col>

					<a-col :span="4">
						<a-button type="primary" html-type="submit" @click="getUserList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">管理员列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="lockUser">
							锁定
						</a-button>
						<a-button type="primary" class="marginr20" @click="passwordUpdate">
							重置密码
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteUser">
							批量删除
						</a-button>
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :total="total_page * 10"
				 :data-source="userList" rowKey="admin_id" :scroll="{ x: 1000, }" :show-total="(total) => `Total ${total} items`"
				 size="small">
					<span slot="is_lock" slot-scope="is_lock">
						<a-tag color="red" v-if="is_lock == 1">
							锁定
						</a-tag>
						<a-tag color="blue" v-if="is_lock == 2 || is_lock == 0">
							正常
						</a-tag>
					</span>
				</a-table>
			</div>
		</div>

		<a-modal v-model="addEditShow" title="新增管理员" width="800px" :footer="null">
			<a-spin :spinning="detailLoading">
				<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }"
				 style="margin: 0 auto; width: 100%">
					<a-row :gutter="24">
						<a-col :span="12">
							<a-form-model-item label="选择类型：" prop="type_id">
								<a-select style="width: 100%" placeholder="请选择账号类型" v-model="info.type_id" @change="getuRoleList(true)">
									<a-select-option v-for="(item,index) in typeList" :key="index" :value="item.value">
										{{item.name}}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="选择角色：" prop="role_id">
								<a-select style="width: 100%" placeholder="请选择角色" v-model="info.role_id">
									<a-select-option v-for="(item,index) in uroleList" :key="index" :value="item.role_id">
										{{item.role_name}}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>						
						<a-col :span="12" v-if="info.type_id==2">
							<a-form-model-item label="所属运营商：" prop="corp_id">
								<a-select style="width: 100%" placeholder="请选择所属运营商" v-model="info.corp_id">
									<a-select-option v-for="(item,index) in corpList" :key="index" :value="item.corp_id">
										{{item.corp_name}}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="登录帐号：" prop="admin_name">
								<a-input v-model="info.admin_name" :disabled="info.admin_id" placeholder="请输入登录帐号" />
							</a-form-model-item>
						</a-col>						
						<a-col :span="12">
							<a-form-model-item label="联系人：" prop="real_name">
								<a-input v-model="info.real_name" placeholder="请输入联系人" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="单位电话：" prop="contact">
								<a-input v-model="info.contact" placeholder="请输入单位电话" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="手机号码：" prop="mobile">
								<a-input v-model="info.mobile" placeholder="请输入手机号码" />
							</a-form-model-item>
						</a-col>
					</a-row>
					<a-row :gutter="24" v-if="info.type_id==3">
						<a-col :span="12">
							<a-form-model-item label="管理区域：" prop="region">
								<a-tree-select style="width:570px;"
									:multiple="true"
									:tree-data="treeData"
									:dropdown-style="{maxHeight:'200px',overflow:'auto'}"
									v-model="info.region"
									tree-checkable="true"
									allowClear
									placeholder="请选择管理区域"
									@change="selectTree"
									/>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>

				<div class="model-footer">
					<div class="content-btns" v-if="isAdd">
						<a-button type="primary" class="marginr20" @click="addUser()">
							保存
						</a-button>
						<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
							取消
						</a-button>
					</div>
					<div class="left" v-if="!isAdd">
						<a-button type="primary" class="marginr20" @click="addUser('edit')">
							修改
						</a-button>
						<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
							取消
						</a-button>
					</div>
					<div class="right" v-if="!isAdd" :key="editIndex">
						<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
							上一条
						</a-button>
						<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
							下一条
						</a-button>
					</div>
				</div>
			</a-spin>
		</a-modal>

		<a-modal v-model="passwordShow" title="修改密码" width="400px" :footer="null">
			<a-form-model ref="rulepassForm" :model="password" :rules="passwordRules" :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }"
			 style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="登录帐号：">
							<a-input v-model="password.admin_name" :disabled="true" placeholder="请输入登录帐号" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="密码：" prop="password">
							<a-input v-model="password.password" placeholder="请输入密码" type="password" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="确定密码：" prop="npassword">
							<a-input v-model="password.npassword" placeholder="请输入确定密码" type="password" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>

			<div class="model-footer">
				<div class="left">
					<a-button type="primary" class="marginr20" @click="updatePassword">
						修改
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="passwordShow = false">
						取消
					</a-button>
				</div>
				<div class="right" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
		</a-modal>

	</div>
</template>

<script>
	import http from '../http'
	import areaData from "../areadata";
	import moment from "moment";

	const columns = [
		{
			title: "登录账号",
			dataIndex: "admin_name",
		},
		{
			title: "账号类型",
			dataIndex: "type_name",
		},
		{
			title: "所属角色",
			dataIndex: "role_name",
		},
		{
			title: "联系人",
			dataIndex: "real_name",
		},
		{
			title: "联系电话",
			dataIndex: "contact",
		},
		{
			title: "手机号码",
			dataIndex: "mobile",
		},
		{
			title: "登录次数",
			dataIndex: "logins",
		},
		{
			title: "最后登录时间",
			dataIndex: "last_login",
		},
		{
			title: "状态",
			dataIndex: "is_lock",
			scopedSlots: {
				customRender: 'is_lock'
			},
		},
		
	];

	export default {
		data() {
			return {
				expand: false,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				// 添加与编辑的数据
				info: {
					type_id:0,
					admin_id: undefined,
					admin_name: '',
					real_name: '',
					contact: '',
					mobile:'',
					corp_id: undefined,
					role_id: undefined,
					region:[],
				},

				rules: {
					type_id: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}, ],
					role_id: [{
						required: true,
						message: '请选择角色',
						trigger: 'change'
					}, ],
					admin_name: [{
						required: true,
						message: '请输入帐号',
						trigger: 'blur'
					}, ],
					real_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					contact: [{
						required: true,
						message: '请输入单位电话',
						trigger: 'blur'
					}, ],
					mobile: [{
						required: true,
						message: '请输入手机号码',
						trigger: 'blur'
					}, ],
				},

				// 搜索参数
				searchData: {
					admin_name: '',
					real_name: '',
					type_id: 0,
					is_lock: 0, // 1锁定2正常
					page: 1, // 页码
				
				},
				// 多少条数据 * 10
				total_page: 0,
				userList: [],

				addEditShow: false,
				isAdd: false,

				// 选择的数据
				selectedRowDataList: [],
				selectedRowKeys: [],

				// 运营商列表
				corpList: [],
				roleList: [],
				uroleList: [],
				typeList: [],
				// 当前是第几条编辑信息
				editIndex: 0,

				detailLoading: false,

				passwordShow: false,
				// 添加与编辑的数据
				password: {
					admin_id: 0,
					admin_name: '',
					password: '',
					npassword: ''
				},
				passwordRules: {
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, ],
					npassword: [{
						required: true,
						validator: this.validatePass,
						trigger: 'blur'
					}, ],
				},
				
				value: [],
				treeData: [],
			};
		},
		created() {
			this.getCorpList()
			this.getTypeList()
			this.getRoleList()
		},
		mounted() {
			this.getUserList()
			let data = []
			let datav = []
			areaData.forEach((v) => {
				data = []
				data.id = parseInt(v.code)
				data.value = v.code
				data.title = v.name
				data.children = []
				v.children.forEach((vv) => {
					datav = []
					datav.id = parseInt(vv.code)
					datav.value = vv.name
					datav.title = vv.name
					data.children.push(datav)
				})
				this.treeData.push(data)
			})
			console.log(this.treeData)
		},
		methods: {
			moment,
			onChange(e) {
				console.log(`checked = ${e.target.checked}`);
			},
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},
			selectTree(e){
				this.info.region = e
			},
			handleReset() {
				this.searchData = {
					admin_name: '',
					real_name: '',
					type_id:0,
					is_lock: 0, // 1锁定2正常
					page: 1, // 页码
				}
				this.getUserList()
			},

			lockUser() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择管理员');
					return
				}

				this.$confirm({
					title: '是否锁定选中管理员?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.userList.forEach((v) => {
							if (this.selectedRowKeys.includes(v.admin_id)) {
								selectDatas.push(v.admin_id)
							}
						})
						http({
							method: 'post',
							url: '/api.admin.tolock/',
							data: {
								admin_id: selectDatas.join()
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('锁定管理员成功');
								this.getUserList()
							} else {
								this.$message.error(res.message);
							}
						})
					}
				});
			},

			// 修改密码
			passwordUpdate() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择管理员');
					return
				}
				this.password = {
					admin_id: 0,
					admin_name: '',
					password: '',
					npassword: ''
				}
				let selectDatas = []
				this.userList.forEach((v) => {
					if (this.selectedRowKeys.includes(v.admin_id)) {
						selectDatas.push(v)
					}
				})
				this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
				this.editIndex = 0
				this.password.admin_name = this.selectedRowDataList[this.editIndex].admin_name
				this.password.admin_id = this.selectedRowDataList[this.editIndex].admin_id
				this.passwordShow = true
			},

			// 删除
			deleteUser() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择管理员');
					return
				}

				this.$confirm({
					title: '是否删除选中管理员?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.userList.forEach((v) => {
							if (this.selectedRowKeys.includes(v.admin_id)) {
								selectDatas.push(v.admin_id)
							}
						})
						http({
							method: 'post',
							url: '/api.admin.del/',
							data: {
								admin_id: selectDatas.join()
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除管理员成功');
								this.getUserList()
							} else {
								this.$message.error(res.message);
							}
						})
					}
				});
			},

			// 获取运营商列表
			getCorpList() {
				http({
					method: 'post',
					url: '/api.corp.treelist/',
					data: {}
				}).then((res) => {
					this.corpList = res.data
				})
			},
			// 获取角色列表
			getRoleList() {
				http({
					method: 'post',
					url: '/api.admin.role.treelist/',
					data: {type_id:0}
				}).then((res) => {
					this.roleList = res.data
				})
			},			
			// 获取角色列表
			getuRoleList(isTrue) {
				http({
					method: 'post',
					url: '/api.admin.role.treelist/',
					data: {type_id:this.info.type_id}
				}).then((res) => {
					if(res.code=='200'){
						this.uroleList = res.data
						if(isTrue)this.info.role_id = undefined
					}
				})
			},
			// 请求管理员列表
			getUserList() {
				http({
					method: 'post',
					url: '/api.admin.list/',
					data: this.searchData
				}).then((res) => {
					this.userList = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 添加管理员
			addUser(type) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						var region="";
						if(this.info.region==[]||this.info.region == undefined)region="";
						else region = this.info.region.toString();
						http({
							method: 'post',
							url: '/api.admin.add/',
							data: {
								...this.info,
								admin_id: this.info.admin_id || 0,
								corp_id: this.info.corp_id || 0,
								region: region,
							}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改管理员信息成功' : '添加管理员成功');
								this.getUserList()
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
			},
			getTypeList() {
				http({
					method: 'post',
					url: '/api.config.list/',
					data: {}
				}).then((res) => {
					if(res.code=='200'){
						this.typeList = res.data.type
					}
				})
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						admin_id: undefined,
						admin_name: '',
						real_name: '',
						contact: '',
						mobile: '',
						corp_id: undefined,
						role_id: undefined,
					}
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择管理员');
						return
					}

					let selectDatas = []
					this.userList.forEach((v) => {
						if (this.selectedRowKeys.includes(v.admin_id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					this.getWebTextDetail()
					this.isAdd = false
				}

				this.addEditShow = true
			},
			// 详情
			getWebTextDetail() {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.admin.detail/',
					data: {
						admin_id: this.selectedRowDataList[this.editIndex].admin_id
					}
				}).then((res) => {
					if(res.code=='200'){
						this.info = res.data
						this.getuRoleList(false)
						this.detailLoading = false
					}
				})
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				if (this.passwordShow) {
					this.password.admin_name = this.selectedRowDataList[this.editIndex].admin_name
					this.password.admin_id = this.selectedRowDataList[this.editIndex].admin_id
				} else {
					this.getWebTextDetail()
				}
			},
			validatePass(rule, value, callback) {
				if (this.password.password != this.password.npassword) {
					callback(new Error('两次密码不相同，请确定'));
				} else {
					callback();
				}
			},
			updatePassword() {
				this.$refs.rulepassForm.validate(valid => {
					
					if (valid) {
						this.password.admin_name = undefined
						http({
							method: 'post',
							url: '/api.admin.updatepwd/',
							data: this.password
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('密码修改成功');
								this.getUserList()
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
		},
	};
</script>
<style lang="less">
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}
	.ant-form-item-control{
		line-height: 22px;
	}
	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}


	.tes {
		width: 90%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.table-th {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e8e8e8;
			height: 30px;

			div {
				width: 33.33%;
				box-sizing: border-box;
				height: 30px;
				padding-left: 20px;
				font-weight: 700;
				border-right: 1px solid #e8e8e8;
				display: flex;
				align-items: center;

				&:last-child {
					border-right: none;
				}
			}
		}

		.taes {
			display: flex;
			align-items: center;

			&>div {
				width: 33.33%;
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 50px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 20px;
				}
			}

			.left {
				height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				height: 300px;
				border-right: 1px solid #e8e8e8;
			}

			.right {
				height: 300px;
			}
		}
	}
</style>
